
.log-cont{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-items: center;

}
.log-form{
  width: 450px ;
  height: 450px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  z-index: 10px;
}

.log-form>img{
  width: 350px;
}
.invoice_div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn{
  background-color: rgb(11, 135, 236);
  margin: 0px 0px 0px 10px;
  padding: 10px;
  border-radius: 10px;
  z-index: 2;
  cursor: pointer;
  font-weight: 400;
  color: white;
}
.datepicker{
  height: 10;
}
.btn:hover{
  background-color: rgb(109, 188, 109);
}
@media only screen and (max-width:700px) {
  .log-cont{
    overflow: hidden;

  }
  .log-form{
   width: 410px;
   height: 410px;
   line-height: 0.09px;
  }
}

@media only screen and (max-width:573px) {
  .log-cont{
    overflow: hidden;
  }
  .log-form{
   width: 320px;
   height: 400px;
   line-height: 0.09px;
  }
  
}
@media only screen and (max-width:320px) {
  
  .log-form{
   width: 250px;
   height: 370px;
   line-height: 0.09px;
   font-size: 8px !important;
  }
   input{
   font-size: 10px;
  }
}