
.upper-header{
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
 
  .upper-header>.img{
    width: 100px;
    margin-left: 30px;
  }
  .upper-header>.img2{
    width: 160px;
    margin-left: 200px;
  
  }
  .nav-header{
    height: 70px;
    width: 100vw;
  }
  .upper-header>.left-corner{
    position: absolute;
    height: 60px;
    right: 0;
    margin-top: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
     justify-content: center;
  }
  .left-corner>p{
    margin-right: 2px;
    margin-top: 4px;
    height: 60px;
    width: 130px;
    text-align: center;
    display: inline;
    justify-content: center;
    align-content: center;
    padding-top: 20px;
    font-style: oblique;
    font-size: 12px;
    text-decoration: solid;
    font-weight: bold;
  }

  .signout:hover{
    background-color: #8c846d;
    color: white;
    cursor: pointer;
  }
  
  @media (max-width: 750px){
    .upper-header{
      flex-direction: column;
      height: 100px;
      justify-items: center;
      align-items: center;
      margin-top: 10px;
    }
    .upper-header>.left-corner{
        position: relative;
    }
    .upper-header>.img{
        width: 100px;
       margin-left: 0px;

      }
      .left-corner>p{
          font-size: 70%;
           height: 40px;
           width: 100px;
           padding-top: 10px;

      }
      
  }