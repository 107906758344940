
.main{
    width: 98% ;
    height: 0px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    border-top:  #d2d73a;
}



.header{
    right: 1px;
    float: right;
    width: 100%;
    display: flex;
    align-content: center;
    padding: 10px;
    padding-left: 30px;
    letter-spacing: 3px;
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: rgba(51, 51, 43, 0.137);
    letter-spacing: 10px;
}
.header>.create{
   position: absolute;
   right: 50px;
   display: flex;
   justify-content: space-between;
}
.header>.create>.plus{
   font-size: 20px;
   margin-right: 10px;
   border: 1px #ad9d71 solid;
   padding-top: 4px;
   padding-right: 4px;
   padding-left: 4px;
   border-radius: 30px;
   align-self: center;
   height: 30px;
   background-color: #d2d73a;
}
.client-card{
   width:80%;
   height:680px;
   padding-left: 13px;
   padding-top: 20px;
   margin:10px;
   border-radius: 20px;
   background-color: rgba(252, 252, 250, 0.938);
   overflow: auto;
   
}
.client-card>div{
   text-align: left;
}
.client-card>div>b{
   width: 140px;
   height: 26px;
   font-size: 12px;
   text-align: left;
}
.client-card>div>p{
    height: 26px;
    font-size: small;
   text-align: left;
   font-family: Arial, Helvetica, sans-serif;

}
.header>.create>.plus:hover{
  cursor: pointer;  
  color: #11abed;
}
.card{
   width: 100%;
   display: flex;
   margin-top: 10px;
   padding-top: 20px;
   padding-left:20px;
   flex-direction: row;
}
.row{
   display: flex;
   flex-direction: row;
}
.row2{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
}

.increase{
   border-color:black 2px solid;
   border-width:2px;
   border-style:solid;
   margin-right:auto;
   margin-left:auto;
   border-radius:20px;
   height:40px;
   align-items: center;
   font-size: 12px;
   width:290px;
   margin-bottom: 12px;
   text-align:center;
   display:flex;
   justify-content:center
}
.increase:hover{
   background-color: #d2d73a;
   cursor: pointer;
}
.admin-section{
   width: 100%;
 
}
.ant-table {
   font-size: 20px;

   width:100vw;
 }
.chart-size{
   width: 420px;
   height: 420px;
}

.dashboard-header{
   display: flex;
   width: 60vw;
   justify-content: center;
   margin-left: auto;
   margin-right: auto;
}
.companyTitle{
   font-size:20px;
   word-spacing: 10px;
   letter-spacing: 10px;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.tag{
   font-size: 2em;
   height: 40px;
   border-radius: 10px;
   text-align: center;
   padding: 10px;
}
@media(max-width: 750px) {
 .header{
    flex-direction: column;
 }
 .header>.create{
    position: relative;  
    right: 0px;
 }
 .chart-size{
    overflow: hidden;
   width: 230px;
   height: 220px;
}
 .main{
   font-size: 10px;
 }

 .card{
   overflow: hidden;
   width: 100%;
   margin-top: 10px;
   padding-right:0px;
   padding-left:0px;
   flex-direction: column;
}
.client-card{
   width:320px;
   height:630px;
   margin-left: auto;
   margin-right: auto;


   border-radius: 20px;
}
.ant-table{
   overflow: hidden;
   overflow-x:auto;
   font-size: 12px;
   margin: 0px;
}

}