.data,.data-form {
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: 440px;
    align-self: center;
    margin-top: 40px;
  }
.back{
      font-size: 20px;
      position: absolute;
      right: 10px;
      cursor: pointer;
      width: 80px;
      justify-content: center;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      text-align: center;
      color: white;
  }

  
 @media only screen and (max-width:700px) {
      .data{
          overflow: hidden;
          width: 280px; 
      }
      .data-form{
        overflow: hidden;  
        height:820px;
        width: 280px;
      }
      .main-create{
          width: 320px;
          border-radius: 20px;
          height:880px;
          margin-left: auto;
          margin-right: auto;
      }
      .main-create2{
        width: 320px;
        border-radius: 20px;
        height:800px;
        margin-left: auto;
        margin-right: auto;
      }
 } 
   
 @media only screen and (max-width:400px) {
    .data{
        overflow: hidden;
        width: 280px;
        height: 300px;
    }
    .data-form{
        overflow: hidden;  
        width: 300px;

      }
} 