.vertical{
  display: none;
}
.horizontal>ul{
    background-color:#11abed;
    padding-left: 16px;
}
.horizontal>ul>li{
  color: white;
  font-weight: bold;
}


@media(max-width: 750px) {
    .horizontal>ul>li{
      font-size: 10px;
    }
    .vertical{
        display: block;
      }
      
}
@media(max-width: 580px) {
  .horizontal>ul>li{
    font-size: 12px;
  }
  .vertical{
      display: block;
    }
    
}